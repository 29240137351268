import React, {useState} from "react";
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "utils/i18n.js";
import { Trans } from "@lingui/macro";
import Layout from "components/layout/layout";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import {ShoppingCart} from "../../utils/shop"
import {Link} from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const IndexPage = ({location, data}) => {

    const [cart, setCart] = useState(new ShoppingCart());
    const locale = getLocaleFromPath(location.pathname);

    function changeAmount(e) {
        let value = Number.parseInt(e.target.value);
        if (isNaN(value) || value===null || value ==='' || value>e.target.max || value<1) {
            return;
        }

        cart.changeAmount(e.target.dataset.id, value);
        setCart(new ShoppingCart());
    }

    function remove(id) {
        cart.remove(id);
        //new object reference must be passed, to handle change;
        setCart(new ShoppingCart());
    };

    function clearCart(e) {
        e.preventDefault();
        cart.clear();
        //new object reference must be passed, to handle change;
        setCart(new ShoppingCart());
    };

    return (
        <Layout location={location}>
            <Header locale={locale}/>
            <div className="MainContent">
                <section id="contact">
                    <div className="container">
                        <h2>Košík</h2>
                        <table id="cart">
                            <thead>
                                <tr>
                                    <th>Tovar</th>
                                    <th style={{textAlign: "center"}}>Množstvo</th>
                                    <th style={{textAlign: "right", width: "10em"}}>Jendotková cena</th>
                                    <th style={{textAlign: "right", width: "10em"}}>Cena spolu</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th colSpan="3">Spolu</th>
                                    <th style={{textAlign: "right"}}>
                                    {Math.round((cart.getTotal(true))*100)/100} &euro; s DPH<br/>
                                    {Math.round((cart.getTotal(false))*100)/100} &euro; bez DPH
                                    </th>
                                    <th style={{textAlign: "right"}} onClick={clearCart}>
                                        <span className="removeButton"><FontAwesomeIcon icon={faTrashAlt} /></span>
                                    </th>
                                </tr>
                            </tfoot>
                            <tbody>
                                {cart.size() === 0 ?
                                    (<tr><td colSpan="5">Nákupný košík je prázdny</td></tr>) :
                                    (cart.toArray().map((node, index)=>
                                        <tr key={"cartItem" + node.id}>
                                            <td>{node.description}</td>
                                            <td style={{textAlign: "center"}}>
                                                <form onSubmit={(e) => {e.preventDefault()}}>
                                                    <input type="number" data-id={node.id} defaultValue={node.amount} min="1" max="100" onChange={changeAmount}/>
                                                </form>
                                                &nbsp;{node.unit}
                                            </td>
                                            <td style={{textAlign: "right"}}>
                                                {Math.round(node.price1Vat*100)/100} &euro; s DPH<br/>
                                                {Math.round(node.price1NoVat*100)/100} &euro; bez DPH
                                            </td>
                                            <td style={{textAlign: "right"}}>
                                                {Math.round(node.amount * node.price1Vat*100)/100} &euro; s DPH<br/>
                                                {Math.round(node.amount * node.price1NoVat*100)/100} &euro; bez DPH
                                            </td>
                                            <td onClick={()=>remove(node.id)} style={{textAlign: "right"}}>
                                                <span className="removeButton"><FontAwesomeIcon icon={faTrashAlt} /></span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className="buttonHolder">
                            <Link className="button" to={"/" + locale + "/produkty"}><Trans>Späť do obchodu</Trans></Link>&nbsp;
                            {cart.size()>0 && <Link className="button" to={"/" + locale + "/objednavka"}><Trans>Objednávka</Trans></Link>}
                        </div>
                    </div>
                </section>
            </div>
            <Footer locale={locale} />
        </Layout>
    );
};

export default withI18n()(IndexPage);

